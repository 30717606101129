@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: Industry, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: var(--black);
  color: var(--neutral);
  font-weight: 300;
}

html {
  height: 100vh;

  /* Mobile sizing */
  font-size: 12px;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--white);
}

body > div:first-of-type {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: #010101;
  --neutral: #e2e2e2;
  --primary-1: #f01919;
  --primary-2: #05bed6;
  --secondary-1: #ff8e06;
}

.title {
  font-family: GALACTIC VANGUARDIAN NCV;
}

.subtitle {
  font-family: "ChubbyChoo";
}

/* Special classes */
.chubby-choo-vertical-fix {
  position: relative;
  /* top: -2px; */
}
.hover-white:hover{
  color: white !important;
}
.fwbold{
  font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: bold;
}
@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
      0 0 1px #fff,
      0 0 2px #fff,
      0 0 4px #fff,
      0 0 6px rgb(224, 244, 1),
      0 0 8px rgb(225, 255, 0),
      0 0 12px rgb(234, 255, 0),
      0 0 14px rgb(255, 200, 0),
      0 0 16px #ff8e06;
  }
  20%, 24%, 55% {       
    text-shadow: none;
  }
}
@keyframes flashy {
 
  from {
    color: white;
  }
  to {
    color: #ff8e06;
  }
 
}

/* .neonText {
  animation: flashy 1s infinite  !important;
  color: white;
} */

@keyframes blink-animation {
  from {
    color: #ff8e06 ;
  }
  to {
    color: white ;
  }
}
@-webkit-keyframes blink-animation {
  from {
    color: #ff8e06 ;
  }
  to {
    color: white ;
  }
}
.textwhite{
  color: white!important;
}
.textyellow{
  color: #ff8e06 !important;
}
/* .neonText{
  color: #ff8e06 ;
          animation: blink-animation 1s steps(2, end) infinite !important;
          -webkit-animation: blink-animation 1s steps(2, end) infinite !important;
        } */







@media screen(sm) {
  html {
    /* Normal font size  */
    font-size: 16px;
  }

  .chubby-choo-vertical-fix {
    top: -5px;
  }
}
